body{
    color: #DBDBDB;
    background: radial-gradient(ellipse at bottom, #1b2735 100%, #090a0f 0%);
}
.pure-img-responsive{
    max-width: 100%;
    height: auto;
}
/*Add transition to containers so they can push in and out.*/
#layout,#menu,.menu-link{
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
/*This is the parent `<div>` that contains the menu and the content area.*/
#layout{
    position: relative;
    left: 0;
    padding-left: 0;
}
#layout.active #menu{
    left: 150px;
    width: 150px;
}
#layout.active .menu-link{
    left: 150px;
}
/*The content `<div>` is where all your content goes.*/
.content{
    margin: 0 auto;
    padding: 0 2em;
    max-width: 800px;
    margin-bottom: 50px;
    line-height: 1.6em;
}
.header{
    margin: 0;
    color: #333;
    text-align: center;
    padding: 2.5em 2em 0;
    border-bottom: 1px solid #eee;
}
.header h1{
    margin: 0.2em 0;
    font-size: 3em;
    font-weight: 300;
}
.header h2{
    font-weight: 300;
    color: #ccc;
    padding: 0;
    margin-top: 0;
}
.content-subhead{
    margin: 50px 0 20px 0;
    font-weight: 300;
    color: #888;
}
/*The `#menu` `<div>` is the parent `<div>` that contains the `.pure-menu` thatappears on the left side of the page.*/
div.home-menu.pure-menu.pure-menu-horizontal.pure-menu-fixed{
    position: absolute;
}
#menu{
    margin-left: -150px;
    /* "#menu" width */
    width: 150px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    /* so the menu or its navicon stays above all content */
    background: #191818;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
/*All anchors inside the menu should be styled like this.*/
#menu a{
    color: #999;
    border: none;
    padding: 0.6em 0 0.6em 0.6em;
}
/*Remove all background/borders, since we are applying them to #menu.*/
#menu .pure-menu,#menu .pure-menu ul{
    border: none;
    background: transparent;
}
/*Add that light border to separate items into groups.*/
#menu .pure-menu ul,#menu .pure-menu .menu-item-divided{
    border-top: 1px solid #333;
}
/*Change color of the anchor links on hover/focus.*/
#menu .pure-menu li a:hover,#menu .pure-menu li a:focus{
    background: #333;
}
/*This styles the selected menu item `<li>`.*/
#menu .pure-menu-selected,#menu .pure-menu-heading{
    background: #1f8dd6;
}
/*This styles a link within a selected menu item `<li>`.*/
#menu .pure-menu-selected a{
    color: #fff;
}
/*This styles the menu heading.*/
#menu .pure-menu-heading{
    font-size: 110%;
    color: #fff;
    margin: 0;
}
/* -- Dynamic Button For Responsive Menu -------------------------------------*/
/*The button to open/close the Menu is custom-made and not part of Pure. Here'show it works:*/
/*`.menu-link` represents the responsive menu toggle that shows/hides onsmall screens.*/
.menu-link {
    position: absolute;
    display: block;
    /* show this only on small screens */
    top: 0;
    left: 0;
    /* "#menu width" */
    background: #000;
    background: rgba(0,0,0,0.7);
    font-size: 10px;
    /* change this value to increase/decrease button size */
    z-index: 10;
    width: 2em;
    height: auto;
    padding: 2.1em 1.6em;
}
.menu-link:hover,.menu-link:focus {
    background: #000;
}
.menu-link span {
    position: relative;
    display: block;
}
.menu-link span,.menu-link span:before,.menu-link span:after {
    background-color: #fff;
    width: 100%;
    height: 0.2em;
}
.menu-link span:before,.menu-link span:after {
    position: absolute;
    margin-top: -0.6em;
    content: " ";
}
.menu-link span:after {
    margin-top: 0.6em;
}
/* -- Responsive Styles (Media Queries) ------------------------------------- */
/*Hides the menu at `48em`, but modify this based on your app's needs.*/
@media (min-width: 48em){
    .header,.content{
        padding-left: 2em;
        padding-right: 2em;
    }
    #layout{
        padding-left: 150px;
        /* left col width "#menu" */
        left: 0;
    }
    #menu{
        left: 150px;
    }
    .menu-link{
        position: absolute;
        left: 150px;
        display: none;
    }
    #layout.active .menu-link{
        left: 150px;
    }
    div.awards{
        width: 100%;
    }

    div.awards div.pure-u-1-2{
        margin-left: -60px;
        margin-top: 1px;
    }
}
@media (max-width: 48em){
    /* Only apply this when the window is small. Otherwise, the followingcase results in extra padding on the left:* Make the window small.* Tap the menu to trigger the active state.* Make the window large again.*/
    #layout.active{
        position: relative;
        left: 150px;
    }
    div.skills div.pure-u-1-6{
        margin: 20px;
    }
}
div.social{
    margin-bottom: 20px;
}
div.social ul li{
    float: left;
    color: grey;
}
div.social ul{
    list-style-type: none;
    padding: 0;
    overflow: hidden;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    width: 105px;
}
div.social li{
    margin-right: 5px;
    margin-left: 5px;
}
div.Me img{
    width: 250px;
}
div.header h1{
    color: #DBDBDB;
}
div.header h3{
    color: #DBDBDB;
}
div.header{
    border-bottom-width: 0;
}
a.pure-menu-link:hover{
    background-color: #212121;
}
div.skills div.pure-u-1-6 center h4{
    margin-bottom: -12px;
}
div.skills div.pure-u-1-6 center p{
    margin-left: 10px;
    margin-right: 10px;
}
div.skills div.pure-u-1-6 center p{
    font-size: 12px;
    line-height: 15px;
}
div.skills div.pure-u-1-6 center h4{
    font-size: 15px;
}
div.skills div.pure-u-1-6 center h1{
    margin-bottom: -15px;
}
div.awards div.pure-u-1-2 h4{
    margin-top: 0;
}
div.awards div.pure-u-1-2 p{
    margin-top: -20px;
}
div.awards{
    width: 140%;
}
div.linkedIn a li{
    font-size: 50px;
}
div.linkedIn a{
    text-decoration: none;
    color: #ffffff;
}
div.linkedIn{
    list-style-type: none;
    padding: 0;
    overflow: hidden;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    background-color: #2831d4;
    color: #ffffff;
    width: 200px;
    margin-top: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
div.footer{
    width: 700px;
    margin: 0 auto;
}
svg.svg-inline--fa.fa-heart.fa-w-16 path{
    color: #bd2828;
}
div.footer p{
    color: #919191;
    text-align: center;
    margin-left: -30px;
}
div.header center h5 {
    color: #c9c9c9;
}

center h5 a {
    color: #d4d4d4;
}

div.skills-section {
  clear: both;
}
div.skills-section ul {
  margin-left: 15px;
}
div.skills-section ul li {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  transition-duration: 0.3s;
}
div.skills-section ul li:hover {
  color: #0038CB;
}
div.skills-section ul li:hover .skills-bar div.bar {
  background: #0038CB;
  box-shadow: 0 0 0 1px #0038CB;
}
div.skills-section ul li span {
  display: block;
  width: 120px;
}
div.skills-section ul li .skills-bar {
  background: #cdcdcd;
  height: 2px;
  width: calc(100% - 120px);
  position: relative;
  border-radius: 2px;
}
div.skills-section ul li div.skills-bar div.bar {
  position: absolute;
  top: -1px;
  height: 4px;
  background: #0038CB;
  box-shadow: 0 0 0 #fff;
  border-radius: 5px;
}


div.work small {
  display: block;
  opacity: 0.7;
}
div.work ul li {
  position: relative;
  margin-left: 15px;
  padding-left: 25px;
  padding-bottom: 15px;
}
div.work ul li:hover::before {
  animation: circle 1.2s infinite;
}
div.work ul li:hover {
  color: #0038CB;
}
